import React, { useState } from 'react';
import './InviteCodeInput.css';

const DOMAIN_SUFFIX = '@docuextract.user.fr8labs.co';

const inputStyles = {
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: '15px',
    borderRadius: '4px',
    overflow: 'hidden',
    backgroundColor: '#f0f2f5',
    border: '1px solid #ced4da',
    height: '44px', // Fixed height for consistency
  },
  input: {
    flex: '0 0 120px',
    padding: '0 15px',
    fontSize: '16px',
    border: 'none',
    backgroundColor: '#f0f2f5',
    color: '#212529',
    height: '100%', // Full height of container
    lineHeight: '44px', // Match container height
    outline: 'none'
  },
  domain: {
    flex: 1,
    padding: '0 15px',
    fontSize: '16px',
    backgroundColor: '#f0f2f5',
    color: '#6c757d',
    borderLeft: '1px solid #ced4da',
    lineHeight: '44px', // Match container height
    height: '100%', // Full height of container
  },
  passwordInput: {
    width: '100%',
    padding: '0 15px',
    fontSize: '16px',
    marginBottom: '15px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    backgroundColor: '#f0f2f5',
    height: '44px',
    lineHeight: '44px'
  },
  button: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px'
  },
  showButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    marginBottom: '10px',
    width: '100%'
  }
};

function InviteCodeInput({ onSubmit }) {
  const [inviteCode, setInviteCode] = useState('');
  const [username, setUsername] = useState('');
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [error, setError] = useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const fullUsername = username + DOMAIN_SUFFIX;
      await onSubmit(inviteCode, fullUsername);
    } catch (error) {
      setError(error.message || 'An error occurred during login');
    }
  };

  const toggleShowInviteCode = () => {
    setShowInviteCode(!showInviteCode);
  };

  return (
    <div className="invite-code-input-overlay">
      <div className="invite-code-input-lightbox">
        <img src="/logo.png" alt="Fr8labs Logo" className="company-logo" />
        <h2>Welcome to Fr8labs DocuExtract</h2>
        <p>Please enter your credentials to access the platform.</p>
        {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit} name="login" method="post">
          <div style={inputStyles.container}>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value.toUpperCase())}
              placeholder="DEMO"
              required
              style={inputStyles.input}
            />
            <div style={inputStyles.domain}>
              {DOMAIN_SUFFIX}
            </div>
            {/* Hidden input for password manager with full username */}
            <input
              type="text"
              name="username"
              autoComplete="username"
              value={username + DOMAIN_SUFFIX}
              readOnly
              style={{ display: 'none' }}
            />
          </div>
          
          <input
            type={showInviteCode ? "text" : "password"}
            name="password"
            autoComplete="current-password"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            placeholder="••••••••••••"
            required
            style={inputStyles.passwordInput}
          />
          
          <button 
            type="button" 
            onClick={toggleShowInviteCode}
            style={inputStyles.showButton}
          >
            {showInviteCode ? "Hide" : "Show"} Invite Code
          </button>
          
          <button 
            type="submit"
            style={inputStyles.button}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default InviteCodeInput;