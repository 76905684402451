import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));

  const logout = useCallback(() => {
    setToken(null);
    setIsLoggedIn(false);
    setCompanyId(null);
    localStorage.removeItem('token');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('fr8labs_auth_token');
    localStorage.removeItem('adminUserId');
  }, []);

  const isTokenValid = useCallback((token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      if (!decoded.exp) return false;
      
      // Check if token is expired (with 5 minute buffer)
      const currentTime = Date.now() / 1000;
      return decoded.exp > (currentTime + 300);
    } catch (error) {
      console.error('Error decoding token:', error);
      return false;
    }
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedCompanyId = localStorage.getItem('companyId');
    
    if (storedToken && storedCompanyId && isTokenValid(storedToken)) {
      setToken(storedToken);
      setCompanyId(storedCompanyId);
      setIsLoggedIn(true);
    } else if (storedToken) {
      // Token exists but is invalid - clean up
      logout();
    }
  }, [isTokenValid, logout]);

  // Set up axios interceptor for handling expired tokens
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  const handleInviteCodeSubmit = async (inviteCode, username) => {
    try {
      const response = await axios.post('/company_login', { 
        invite_code: inviteCode,
        username: username // Add username to payload
      });
      
      if (response.data.token && isTokenValid(response.data.token)) {
        setToken(response.data.token);
        setIsLoggedIn(true);
        setCompanyId(response.data.company_id);
        localStorage.setItem('companyName', response.data.company_name);
        localStorage.setItem('companyId', response.data.company_id);
        localStorage.setItem('fr8labs_auth_token', response.data.fr8labs_auth_token);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('adminUserId', response.data.adminUserId);
        return true;
      }
    } catch (error) {
      console.error('Error submitting invite code:', error);
      if (error.response?.status === 401) {
        throw new Error('Invalid credentials');
      } else {
        throw new Error('An error occurred during login');
      }
    }
    return false;
  };

  return {
    token,
    isLoggedIn,
    companyId,
    handleInviteCodeSubmit,
    logout,
    isTokenValid
  };
};